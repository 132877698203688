import { FunctionComponent, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { ContextType } from '@ui/components/context';
import LineButton from '@pages/auth/components/social-networks/line-button';
import NewsletterForm from './newsletter-form';
import LoadingBox from '@shares/loading';
import { AlertType } from '@shares/alert-box';
import { DRIVEHUB_API } from '@config';
import './style.scss';

interface UserNotificationBoxProps {
  prefix: string;
  toggleAlertBox: (errorType: AlertType, massage?: string) => void;
}

interface ConsumerNotificationResp {
  enable_newsletter_email: boolean;
  enable_newsletter_sms: boolean;
  enable_line_notification: boolean;
}
const UserNotificationBox: FunctionComponent<UserNotificationBoxProps> = ({ toggleAlertBox }) => {
  const { auth, accessToken } = useContext(ContextType);

  const [noti, setNoti] = useState<ConsumerNotificationResp>({
    enable_newsletter_email: false,
    enable_newsletter_sms: false,
    enable_line_notification: false
  });
  const [fetching, setFetching] = useState<boolean>(true);
  const { consumer } = auth;
  const fetchConsumerNotifications = () => {
    axios
      .get<ConsumerNotificationResp>(`${DRIVEHUB_API}/v1/consumer/notifications`, {
        headers: {
          Authorization: accessToken
        }
      })
      .then(({ data }) => {
        setFetching(false);
        setNoti(data);
      })
      .catch((e) => console.log(e.response.status));
  };
  useEffect(() => {
    if (consumer.id == 0) return;
    fetchConsumerNotifications();
  }, [consumer]);

  const handleOnChange = (connectStatus: boolean, respStatus?: number) => {
    if (respStatus && respStatus != 200) return toggleAlertBox(AlertType.Error);
    if (connectStatus === true) return fetchConsumerNotifications();
    setNoti({ ...noti, enable_line_notification: connectStatus });
  };

  return (
    <div className="notifications p-3 p-lg-0">
      <h2>การแจ้งเตือน</h2>
      <div className="notification">
        <div className="notification-item col-12 col-md-6 mb-3 p-0">
          <p className="title">ข่าวสารและโปรโมชั่น</p>
          <p>รับข่าวสารเกี่ยวกับแคมเปญล่าสุด, รหัสโปรโมชั่น, ส่วนลด และฟีเจอร์ใหม่</p>
          <NewsletterForm
            enableEmail={noti.enable_newsletter_email}
            enableSMS={noti.enable_newsletter_sms}
            toggleAlertBox={toggleAlertBox}
          />
        </div>
        <div className="notification-item col-12 col-md-6 p-0">
          <p className="title">บัญชีสำหรับรับการแจ้งเตือน</p>
          <p>สำหรับรับการแจ้งเตือนที่สำคัญเกี่ยวกับรายละเอียดการจองและการยกเลิก</p>
          <div className="line-box">
            <div>
              <img src="/assets/shares/line_88.png" alt="line-icon" className="line-icon mr-2" />
              <span className="font-weight-bold">LINE Bot</span>
            </div>
            {fetching ? (
              <LoadingBox className="loading--btn" />
            ) : (
              <LineButton
                consumerID={consumer.id}
                isConnected={noti.enable_line_notification}
                onChange={(isConnected, respStatus) => handleOnChange(isConnected, respStatus)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserNotificationBox;
