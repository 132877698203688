import { FunctionComponent, useContext, useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { ContextType } from '@ui/components/context';
import { DRIVEHUB_API, LINE_LIFF_ID } from '@config';
import LoadingBox from '@shares/loading';
import { canUseDOM } from '@utils';
import './style.scss';
interface LineButtonProps {
  isConnected: boolean;
  onChange: (isConnected: boolean, respStatus?: number) => void;
  consumerID: number;
}

const deleteLineAccount = (
  consumerToken: string,
  successHandle: () => void,
  errorHandle: (respStatus: number) => void
) => {
  axios
    .delete(`${DRIVEHUB_API}/v1/consumers/line_account`, {
      headers: {
        Authorization: consumerToken
      }
    })
    .then(() => {
      setTimeout(() => {
        successHandle();
      }, 1000);
    })
    .catch((e) => {
      setTimeout(() => {
        errorHandle(e.response.status);
      }, 1000);
      console.log(e.response.message);
    });
};

const connectLineAccount = (consumerID: number) => {
  const liffLink = () => {
    const state = {
      booking_id: 0,
      last_name: '',
      source: '',
      consumer_id: `${consumerID}`
    };
    const stateStr = encodeURIComponent(JSON.stringify(state));

    return `https://liff.line.me/${LINE_LIFF_ID}?state=${stateStr}`;
  };
  if (canUseDOM()) window.open(liffLink());
};

const LineButton: FunctionComponent<LineButtonProps> = ({ isConnected, onChange, consumerID }) => {
  const ctx = useContext(ContextType);
  const [loading, setLoading] = useState<boolean>(isConnected);

  const handleOnClickLine = (currentStatus: boolean, consumerToken: string) => {
    if (consumerToken == '') return;
    setLoading(true);
    switch (currentStatus) {
      case true:
        deleteLineAccount(
          consumerToken,
          () => {
            onChange(false);
            setLoading(false);
          },
          (respStatus) => {
            onChange(false, respStatus);
            setLoading(false);
          }
        );
        break;
      case false:
        connectLineAccount(consumerID);
        setTimeout(() => {
          onChange(true);
          setLoading(false);
        }, 8000);
        break;
      default:
        return;
    }
  };

  return (
    <Button
      variant={isConnected ? 'outline-danger' : 'outline-primary'}
      className="btn--line-connect"
      onClick={() => handleOnClickLine(isConnected, ctx.accessToken || '')}
      disabled={loading ? true : false}
    >
      {loading ? <LoadingBox className="loading--btn-sm" /> : isConnected ? 'ยกเลิกการแจ้งเตือน' : 'รับการแจ้งเตือน'}
    </Button>
  );
};

export default LineButton;
