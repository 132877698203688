import { FunctionComponent, useEffect, useState } from 'react';
import { object, boolean, InferType } from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { withTrans } from '@shares/locales/hoc';
import { withFormik, FormikProps } from 'formik';
import axios from 'axios';
import { DRIVEHUB_API } from '@config';
import LoadingBox from '@shares/loading';
import { readCookie } from '@utils';
import { AlertType } from '@shares/alert-box';
import _ from 'lodash';
import './style.scss';

export const newsletterSchema = () =>
  object({
    enableSMS: boolean().required().default(false),
    enableEmail: boolean().required().default(false)
  });

export type NewsletterFormValues = InferType<ReturnType<typeof newsletterSchema>>;

const NewsletterFormField: FunctionComponent<TranslationProps & FormikProps<NewsletterFormValues>> = ({
  handleSubmit,
  initialValues,
  values,
  setFieldValue,
  status,
  isSubmitting,
  t
}) => {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  useEffect(() => {
    const isEqual = _.isEqual(initialValues, values);
    if (!isEqual) return setIsChanged(true);
    setIsChanged(false);
  }, [values]);
  return (
    <div className="newsletter">
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group controlId="formNewsletter">
          <Form.Check
            id="newsletter-email"
            inline
            type="checkbox"
            onChange={() => setFieldValue('enableEmail', !values.enableEmail)}
            checked={values.enableEmail}
            label="Email"
            className="mr-5"
          />
          <Form.Check
            id="newsletter-sms"
            inline
            type="checkbox"
            onChange={() => setFieldValue('enableSMS', !values.enableSMS)}
            checked={values.enableSMS}
            label="SMS"
          />
        </Form.Group>
        <Button
          type="submit"
          className={`btn--newsletter ${status && status != 200 ? 'error' : ''}`}
          disabled={(isChanged ? false : true) || isSubmitting ? true : false}
        >
          {isSubmitting ? (
            <LoadingBox className="loading--btn-sm" />
          ) : status && status != 200 ? (
            'ลองใหม่อีกครั้ง'
          ) : (
            t('notification.newsletter_submit')
          )}
        </Button>
      </Form>
    </div>
  );
};

interface NewsletterFormProps {
  enableSMS: boolean;
  enableEmail: boolean;
  toggleAlertBox: (errorType: AlertType, massage?: string) => void;
}

const NewsletterForm = withFormik<NewsletterFormProps, NewsletterFormValues>({
  mapPropsToValues: (props) => {
    return {
      enableSMS: props.enableSMS,
      enableEmail: props.enableEmail
    };
  },
  validationSchema: newsletterSchema(),
  handleSubmit: (values, formikBag) => {
    const consumerToken = readCookie('_consumerToken');
    if (consumerToken == '') {
      formikBag.setStatus(404);
      formikBag.setSubmitting(false);
    }
    axios({
      method: 'put',
      url: `${DRIVEHUB_API}/v1/consumer/newsletter`,
      headers: {
        Authorization: consumerToken
      },
      data: {
        enable_newsletter_email: values.enableEmail,
        enable_newsletter_sms: values.enableSMS
      }
    })
      .then(() => {
        setTimeout(() => {
          formikBag.setSubmitting(false);
          formikBag.props.toggleAlertBox(AlertType.Success);
        }, 1000);
        formikBag.setStatus(200);
      })
      .catch((e) => {
        formikBag.props.toggleAlertBox(AlertType.Error);
        formikBag.setStatus(e.response.status);
      });
  },
  enableReinitialize: true
})(withTrans('user')(NewsletterFormField));

export default NewsletterForm;
